import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/styles/BlogCard"
import TagMenu from "../components/TagMenu"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .card {
    padding: 0.5em;
    margin-bottom: 1em;

    a {
      font-family: "Bitter";
      font-size: 0.5em;
      border-bottom: 3px solid black;
    }
  }
`

const BlogLayout = styled.div`
  display: flex;
  background-color: #fff;
`

export const BlogFrame = ({ children }) => <BlogLayout>{children}</BlogLayout>

const Blog = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <BlogFrame>
        <Wrapper>
          {data.allMarkdownRemark.edges.map(({ node }, index) => {
            return (
              <div className="card">
                <h2>{node.frontmatter.title}</h2>
                <p>{node.excerpt}</p>
                <Link to={`/blog${node.fields.slug}`}>Read all</Link>
              </div>
            )
          })}
        </Wrapper>
      </BlogFrame>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
      edges {
        node {
          id
          frontmatter {
            title
            tags
          }
          excerpt(pruneLength: 300)
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Blog
